<template>
  <div class="login-page">
    <v-card class="px-5 pb-10">
      <v-row class="py-0 mt-n5">
        <v-col cols="12" class="my-0 d-flex justify-end align-center">
          <div class="mr-1">
            <v-img class="logo-img" width="80" src="@/assets/AgentOS-Logo.png"></v-img>
          </div>
        </v-col>
        <v-col cols="12">
          <v-img 
            v-if="$store.state.lightTheme"
            src="@/assets/agentPay Logos/Logo/SVG/agentPay - Logo.svg"
            class="px-3"
            contain
            height="100"
          ></v-img>
          <v-img 
            v-else
            src="@/assets/agentPay Logos/Logo/SVG/agentPay - darkLogo.svg"
            class="px-3"
            contain
            height="100"
          ></v-img>
        </v-col>
      </v-row>
      <form class="login-form mt-3">
        <v-row>
          <v-col cols="12" class="my-0">
            <v-text-field
              outlined
              v-model="username"
              label="Username"
              append-icon="mdi-account"
              class="mb-0 pb-0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="my-n5">
            <v-text-field
              outlined
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              label="Password"
              class="input-group--focused"
              @click:append="show = !show"
              @keyup.enter="login"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
          <v-btn
            height="50"
            class="white--text"
            block
            color="#eb5c37"
            @click.prevent="login"
            :loading="isLoading"
            >
            Sign in
            </v-btn>
          </v-col>
        </v-row>

      </form>
      <div v-if="loginError" class="error-msg">
        <p v-if="invalidCredentials">Invalid username or password; please make sure you have entered your details correctly. If you still can't log in, a Client Administrator on your system can reset your password Alternatively, please contact <a class="white--text" href="mailto:support@agentos.com">support@agentos.com</a></p>
        <p v-if="noPermission">Your user account does not have authorization to view the data contained within this app</p>
        <hr>
        <div class="error-contact-info">
          <ul id="contact-details">
            <li><a class="primary--text" href="mailto:support@agentos.com">support@agentos.com</a></li>
            <li>Call 029 2036 7960</li>
          </ul>
          <ul id="opening-times">
            <li id="opening-times-title">Opening times:</li>
            <li>Mon - Fri: 9am - 5pm</li>
            <li>Sat/Sun: closed</li>
          </ul>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  mounted(){
    this.loginIfAgentOSSessionID();
  },
  data() {
    return {
      btnColor: "#5580ff",
      isLoading: false,
      username: '',
      password: '',
      loginError: false,
      invalidLoginMsg: '',
      invalidCredentials: false,
      noPermission: false,
      show: false,
    }
  },
  methods: {
    //dispatch $store.action
    login() {
      this.isLoading = true
      this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
      })
      .then( () => {
        this.$router.push('/agentpay/dashboard')
        this.isLoading = false
      })
      .catch(err => {
        console.log(Object.values(err))
        if (!err.response) {
          this.noPermission = true
        } else {
          this.invalidCredentials = true
          this.password = ''
        }
        this.loginError = true
        this.isLoading = false
      })
    },
    //login if we are passed a sessionID from AgentOS. If not, do nothing.
    // To do with accounts version of the app
    loginIfAgentOSSessionID() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.has('shortname') && urlParams.has('sessionid')){
        this.isLoading = true
        this.$store.dispatch('loginAgentOSSessionID', {
            shortName: urlParams.get('shortname'),
            sessionID: urlParams.get('sessionid'),
        })
        .then( () => {
          this.$router.push('/agentpay/dashboard')
          this.isLoading = false
        })
        .catch(err => {
          console.log(Object.values(err))
          if (!err.response) {
            this.noPermission = true
          } else {
            this.invalidCredentials = true
            this.password = ''
          }
          this.loginError = true
          this.isLoading = false
        });
      }
    },
  }
}
</script>

<style scoped>
.login-page {
  /* border: 2px solid red; */
  min-width: 320px;
  max-width: 360px;
  padding: 8% 0 0;
  margin: 0 auto 30%;
}

.form {
  /* border: 2px solid green; */
  position: relative;
  z-index: 1;
  /* background: rgb(105,107,108, .8); */
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 20px 20px 40px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  transition: .3s ease;
}

.login-form {
  padding: 0 15px;
}

.error-msg {
  margin-top: 1rem;
  color: #eb5c37;
  font-size: .8rem;
}
  .error-contact-info {
    display: flex;
    margin-top: 1rem;
  }
    #contact-details {
      text-align: left;
    }
    #opening-times {
      list-style-type: none;
      justify-content: start;
    }
    #opening-times-title {
      font-weight: bold;
    }

</style>
