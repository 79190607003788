<template>
  <div class="home">
    <v-container fill-height fluid>
      <v-layout align-center justify-center>
        <v-flex lg9>
          <Login />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Login from '../components/TheLogin.vue'

export default {
  components: {
    Login
  },
  mounted() {
    $zopim(function(){ // eslint-disable-line
      $zopim.livechat.clearAll() // eslint-disable-line
      // $zopim.livechat.removeTags() 
      // what tags...? 
    })
  }
}
</script>

<style scoped>
.home {
  height: 100%;
  width: 100%;
  /* Images from https://www.pexels.com/ */
  background-image: url(../assets/architecture-buildings-city-1769371.jpg);
  /* background: url(../assets/building-clouds-facade-412842.jpg); */
  background-position: center;
  background-size: cover;
}
</style>
